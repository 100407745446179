<template>
  <div id="container" />
</template>

<script>
import { findTaskTransport } from '@/api/task'
export default {
  name: 'Location',
  props: {
    taskId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      markers: [],
      latLngs: [],
      startPosition: {},
      endPosition: {},
      currentPosition: {},
      positions: [],
      task: {},
      currentLatLng: {},
      marker: {},
      amap: {},
      resData: {}
    }
  },
  methods: {
    fetchTaskTransport() {
      findTaskTransport(this.taskId).then(res => {
        if (res.code === 0) {
          this.resData = res.data
          this.startPosition = res.data.start_location
          this.endPosition = res.data.end_location
          this.currentPosition = res.data.current_location
          this.positions = res.data.polyline
          this.task = res.data.task
          this.initMap()
        }
      })
    },
    initMarkers() {
      this.markers = []
      if (this.startPosition.exists === true) {
        let content = ''
        if (this.task.task_type_slug === 'body_feeder') {
          content = '接运点:' + this.startPosition.address
        } else {
          content = '始发地:' + this.startPosition.address
        }
        const startLatLng = new TMap.LatLng(this.startPosition.latitude, this.startPosition.longitude)
        this.markers.push({
          styleId: 'start',
          id: '3',
          position: startLatLng,
          content: content // 标注点文本
        })
        if (this.currentPosition.exists === false) {
          this.currentLatLng = new TMap.LatLng(this.startPosition.latitude, this.startPosition.longitude)
        }
      }
      if (this.endPosition.exists === true) {
        const endLatLng = new TMap.LatLng(this.endPosition.latitude, this.endPosition.longitude)
        this.markers.push({
          styleId: 'end',
          id: '2',
          position: endLatLng,
          content: '目的地:' + this.endPosition.address // 标注点文本
        })
        if (this.currentPosition.exists === false && this.startPosition.exists === false) {
          this.currentLatLng = new TMap.LatLng(this.endPosition.latitude, this.endPosition.longitude)
        }
      }
      if (this.currentPosition.exists === true) {
        this.currentLatLng = new TMap.LatLng(this.currentPosition.latitude, this.currentPosition.longitude)
        this.markers.push({
          styleId: 'current',
          id: '1',
          position: this.currentLatLng,
          content: '当前位置：' + this.currentPosition.address // 标注点文本
        })
      }
      this.marker.updateGeometries(this.markers)
      this.amap.setCenter(this.currentLatLng)
    },
    initPolyline() {
      if (this.positions != null && this.positions.length > 0) {
        this.positions.forEach((position, index) => {
          this.latLngs.push(new TMap.LatLng(position.latitude, position.longitude))
        })
        const poly = new TMap.MultiPolyline({
          id: 'polyline-layer',
          map: this.amap,
          styles: {
            style_blue: new TMap.PolylineStyle({
              color: '#3777FF',
              width: 8,
              borderWidth: 5,
              borderColor: '#FFF',
              lineCap: 'round'
            })
          },
          geometries: [
            {
              id: 'pl_1',
              styleId: 'style_blue',
              paths: this.latLngs
            }
          ]
        })
      }
    },
    initInfo() {
      let content = ''
      if ((this.task.task_type_slug === 'body_feeder' && (this.task.task_status_slug === 'leave' || this.task.task_status_slug === 'destination')) || (this.task.task_type_slug === 'express' && this.task.task_status_slug === 'express_leave')) {
        content = '<div class="info_card info_card_height_250"><div align="left" class="content">'
      } else {
        content = '<div class="info_card info_card_height_200"><div align="left" class="content">'
      }
      content = content + '<br>' + this.task.user_title + '：' + this.task.user_name + '</br><br>联系电话：' + this.task.phone_number + '</br><br>汽车牌照：' + this.task.car_no + '</br><br>任务状态：' + this.task.status_display + '</br>'
      if (this.task.task_type_slug === 'body_feeder') {
        if (this.task.task_status_slug === 'leave') {
          content = content + '<br>距离接运点：' + this.resData.distance_display + '</br>'
        }
        if (this.task.task_status_slug === 'destination') {
          content = content + '<br>距离目的地：' + this.resData.distance_display + '</br>'
        }
      }
      if (this.task.task_type_slug === 'express') {
        if (this.task.task_status_slug === 'express_leave') {
          content = content + '<br>距离目的地：' + this.resData.distance_display + '</br>'
        }
      }
      content = content + '</div></div>'
      const info = new TMap.InfoWindow({
        map: this.amap,
        enableCustom: true,
        position: this.currentLatLng,
        offset: { y: -90, x: -20 },
        content: content
      })
    },
    initMap() {
      const center = new TMap.LatLng(31.981234, 120.894518)
      const map = new TMap.Map(document.getElementById('container'), {
        center: center,
        zoom: 13
      })
      const marker = new TMap.MultiMarker({
        map: map,
        styles: {
          start: new TMap.MarkerStyle({
            // 点标注的相关样式
            width: 34, // 宽度
            height: 46, // 高度
            anchor: { x: 17, y: 23 }, // 标注点图片的锚点位置
            src: 'https://res.anxiaotang.com/assets/9edf6bb1-ad5e-42dc-969d-d4384e09acad', // 标注点图片url或base64地址
            color: '#333', // 标注点文本颜色
            size: 16, // 标注点文本文字大小
            direction: 'bottom', // 标注点文本文字相对于标注点图片的方位
            offset: { x: 0, y: 0 }, // 标注点文本文字基于direction方位的偏移属性
            strokeColor: '#fff', // 标注点文本描边颜色
            strokeWidth: 2 // 标注点文本描边宽度
          }),
          end: new TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 36, y: 32 },
            src: 'https://res.anxiaotang.com/assets/d273876b-2874-4d50-bf7a-7d623845c0e8',
            color: '#333',
            size: 16,
            direction: 'bottom',
            strokeColor: '#fff',
            offset: { x: 0, y: 0 },
            strokeWidth: 2
          }),
          current: new TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 36, y: 32 },
            src: 'https://res.anxiaotang.com/assets/9d12ddcb-a5e4-431c-a1cf-73e7d032a1d8',
            color: '#333',
            size: 16,
            direction: 'bottom',
            strokeColor: '#fff',
            offset: { x: 0, y: 0 },
            strokeWidth: 2
          })
        },
        geometries: []
      })
      this.marker = marker
      this.amap = map
      // 创建标记
      this.initMarkers()
      this.initPolyline()
      // 添加到提示窗
      this.initInfo()
    }
  },
  mounted() {
    this.fetchTaskTransport()
  }
}
</script>

<style lang="scss">
  *{
    margin:0px;
    padding:0px;
  }
  #container{
    max-width:1000px;
    max-height: 700px;
  }
  .info_card {
    display: inline-block;
    margin: 50px auto;
    position: absolute;
    width: 200px;
    height:250px;
    background-color: #ffffff;
    border: 1px solid #000000;
    color: #000000;
  }
  .info_card_height_250 {
    height:250px;
  }
  .info_card_height_200 {
    height:200px;
  }
  .info_card .content{
    margin-top: 10px;
    margin-left: 10px;
  }
</style>
